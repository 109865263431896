import {isNil, isNumber, pickBy} from 'lodash'

export const formatUrl = (
    path: string,
    params?: Record<string, string | number | undefined>,
) => {
    const search = new URLSearchParams();
    let url = path

    if (params) {
        for (const key in params) {
            if (isNil(params[key])) continue;
            const exists = url.includes(`:${key}`);

            if (exists) {
                url = url.replace(`:${key}`, String(params[key]));
                search.delete(key);
            } else {
                search.set(key, String(params[key]));
            }
        }
    }
    if (search.toString()) {
        url += `?${search.toString()}`;
    }

    url = url
        .split("/")
        .filter((e) => !e.includes(":"))
        .join("/");

    return url;
};

export const getErrorMessage = (e: any): string => {
    if ('message' in e) return String(e.message)
    if ('error' in e) return String(e.error)
    if ('errors' in e) {
        if (Array.isArray(e.errors)) return e.errors[0]
        if (typeof e.errors === 'object') return String(Object.values(e.errors)[0])
        return 'generic_error'
    }
    return 'generic_error'
}

export const buildRoute = (route: string, data: Record<string, any> = {}): string => {
    let merged = {...data}
    let path = Object.keys(merged).reduce((acc, key) => {
        if (acc.match(`:${key}`)) {
            acc = acc.replace(`:${key}`, merged[key])
            delete merged[key]
        }
        return acc
    }, route)

    const search = new URLSearchParams(pickBy(merged))

    return `${path}?${search.toString()}`
}

export const getValueChangePercent = (val?: number | string, prevVal?: number | string) => {
    if (isNil(val) || isNil(prevVal)) return 0
    if (!isNumber(+val) || !isNumber(+prevVal)) return 0
    const oldVal = parseFloat(prevVal.toString())
    const newVal = parseFloat(val.toString())

    const res = Math.round((((newVal - oldVal)) * 100 / oldVal))
    if (!Number.isFinite(res)) return 0
    return res
}

export const hexToRgb = (hex?: string) => {
    if(!hex) return null
    if (!hex) return null;
    const bigint = parseInt(hex.replace('#', ''), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
}

export const hexToRgba = (hex?: string, alpha: number = 1) => {
    const rgb = hexToRgb(hex)
    if(!rgb) return undefined
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`
}

export const lightenColor = (color: string, percent: number) => {
    const rgb = hexToRgb(color)
    if(!rgb) return color

    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = rgb.r + Math.round((t - rgb.r) * p)
    const G = rgb.g + Math.round((t - rgb.g) * p)
    const B = rgb.b + Math.round((t - rgb.b) * p)
    return `rgb(${R}, ${G}, ${B})`
}

export const safeParseNumber = (val: any) => {
    if(isNil(val)) return 0
    if(isNumber(val)) return val
    if(typeof val === 'string') {
        if(isNaN(parseFloat(val))) return 0
        return parseFloat(val)
    }
    return 0
}


export const timeStringToSeconds = (time?: string) => {
    if (!time) return 0
    const [minutes, seconds] = time.split(':').map(Number)

    const result = Math.abs(minutes * 60) + seconds
    if (time.includes('-')) return -result
    return result
}
