export const SQUARE_REGISTER = '/oauth2/authorize/square' as const;
export const STATIONS = '/stations' as const;
export const SETTINGS = '/settings' as const;

export const CLUSTERS = '/clusters' as const

export const LOGIN = '/login' as const;
export const REGISTER = '/register' as const;

export const DISPLAYS_LIST = '/displays' as const;

export const STATISTICS = '/statistics' as const;

export const SCREENS_LIST = '/orders/screens' as const;
export const ORDERS_SCREEN = '/orders/screens/:screenId' as const;

export const TV_WELCOME = '/tv/welcome' as const;

export const ADMIN_MENU = '/admin/menu' as const;


export const ADMIN_DEVICES_LIST = '/admin/devices' as const;


export const ROUTES = {
    SQUARE_REGISTER,
    STATIONS,
    LOGIN,
    SETTINGS,
    REGISTER,
    ORDERS_SCREEN,
    DISPLAYS_LIST,
    SCREENS_LIST,
    TV_WELCOME,
    STATISTICS,
    CLUSTERS
}

export const ADMIN_ROUTES = {
    ADMIN_MENU,
    ADMIN_DEVICES_LIST,
}