import axios, {AxiosRequestConfig} from "axios";
import AxiosRetry from 'axios-retry';

export let apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://api.pickpad.ai',
    timeout: 20000,
    timeoutErrorMessage: 'Request timed out'
})


AxiosRetry(apiClient, {
    retries: 5, retryCondition: (error) => {
        console.log(error.code, error?.response?.status)
        return error.code === 'ECONNABORTED' || error.code === 'ECONNRESET' || ![400, 404, 422, 401, 403, 429].includes(error?.response?.status || 0)
    }
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    const accountId = new URLSearchParams(window.location.search).get('accountId')
    if (token) {
        config.headers['authorization'] = `Bearer ${token}`
    }
    if (accountId) {
        config.headers['x-account-token'] = accountId
    }

    return config
})

apiClient.interceptors.response.use(
    (response) => {
        // Return the response as is if it succeeds
        return response;
    },
    (error) => {
        // Check for 401 status
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.assign(`/login?from=${window.location.pathname}`);
        }

        if (error.response) {
            return Promise.reject(error.response.data)
        }

        // Throw the error to propagate it to the calling code
        return Promise.reject(error);
    }
);
export const fetcher = <T = {}>(url: string, body?: Record<string, any> | null, config?: AxiosRequestConfig): Promise<T> => {
    if (body) {
        return apiClient<T>({
            method: 'POST',
            url,
            data: body,
            ...config
        }).then(res => res.data);
    }
    return apiClient.get(url, config).then(res => res.data);
}