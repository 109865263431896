import {useNavigate} from "react-router";
import {useEffect} from "react";
import {App as CapacitorApp} from "@capacitor/app";
import {ROUTES} from "../../routes/routes.list";
import {noop} from "lodash";
import {useAppStore} from "../../entities/app/lib/app.store";

export const useBackButton = () => {
    const navigate = useNavigate();
    const {isTVMode} = useAppStore()

    const onKeyUp = (event: KeyboardEvent) => {
        if (!['BrowserBack', 'Escape', 'Backspace'].includes(event.key)) return;
        event.preventDefault();
        goBack()
    }

    const goBack = () => {
        if (ROUTES.SCREENS_LIST === window.location.pathname || window.location.pathname === ROUTES.DISPLAYS_LIST || window.history.length === 1) {
            // Exit the app on the home page
            CapacitorApp.exitApp().catch(noop);
        } else {
            // Navigate back in browser history
            navigate(-1)
        }
    }

    useEffect(() => {
        if (!isTVMode) return;
        document.body.style.setProperty('scroll-behavior', 'smooth');
        document.body.style.setProperty('navigation-policy', 'focus-only');
        document.addEventListener('keydown', onKeyUp);
        window.addEventListener('tvBackButton', goBack)
        document.addEventListener('tvBackButton', goBack)
        document.addEventListener('ionBackButton', goBack)
        let backButtonListener: Promise<() => void>

        const cleanup = () => {
            document.removeEventListener('keyup', onKeyUp);
            document.body.style.removeProperty('scroll-behavior');
            document.body.style.removeProperty('navigation-policy');
            window.removeEventListener('tvBackButton', goBack)
            document.removeEventListener('tvBackButton', goBack)
            document.removeEventListener('ionBackButton', goBack)
            // Cleanup the listener on component unmount
            backButtonListener.then(e => e())
        }
        try {
            backButtonListener = CapacitorApp.addListener('backButton', ({canGoBack}) => {
                // Example: Custom back button behavior
                if (ROUTES.SCREENS_LIST === window.location.pathname || window.location.pathname === ROUTES.DISPLAYS_LIST || !canGoBack || window.history.length === 1) {
                    // Exit the app on the home page
                    CapacitorApp.exitApp();
                } else {
                    // Navigate back in browser history
                    navigate(-1)
                }
            }).then(e => e.remove).catch(e => {
                return () => {
                }
            });
            return cleanup
        } catch (e) {
            console.log(e)
        }
        return cleanup
    }, [isTVMode]);
}