import {useEffect, useMemo, useState} from "react";

export const useTheme = () => {
    const [theme, setTheme] = useState('');

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light'
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    const isDark = useMemo(() => {
        return theme === 'dark';
    }, [theme]);

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');
        const prefferDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (localTheme) {
            setTheme(localTheme);
        } else if (prefferDark) {
            localStorage.setItem('theme', theme);
            setTheme('dark');
        }
    }, []);
    useEffect(() => {
        if(!theme) return;
        if (isDark) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }, [theme, isDark])

    return {theme, isDark, toggleTheme, setDark: () => {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
        }, setLight: () => {
            setTheme('light');
            localStorage.setItem('theme', 'light');
        }};
}