import React, {lazy} from "react";
import {createBrowserRouter, redirect} from "react-router-dom";
import {ADMIN_ROUTES, ROUTES} from "./routes.list";
import {MainLayout} from "../layout/main.layout";
import {LoaderFunctionArgs} from "react-router";
import {buildRoute, formatUrl} from "../shared/utils/utils";
import {GenericError} from "../entities/eror-page/error-page";
import {IAppStore} from "../entities/app/lib/app.store";
import {set} from "lodash";
import {TvLayout} from "../layout/tv.layout";
import {AdminLayout} from "../layout/admin.layout";
import {NoSidebarLayout} from "../layout/no-sidebar.layout";

// Authorization
const SquareAuthForm = lazy(() => import(/*webpackChunkName: "square-auth-form"*/ "../pages/square-auth-form"));
const Login = lazy(() => import(/*webpackChunkName: "login"*/ "../pages/login-page"));
const Register = lazy(() => import(/*webpackChunkName: "register"*/ "../pages/register-page"));

// Main routes
const Dashboard = lazy(() => import(/*webpackChunkName: "dash"*/ "../pages/dashboard.page"));
const Settings = lazy(() => import(/*webpackChunkName: "settings"*/ "../pages/settings.page"));
const OrdersScreen = lazy(() => import(/*webpackChunkName: "settings"*/ "../pages/order-screen/orders-screen.page"));
const ScreensList = lazy(() => import(/*webpackChunkName: "displays"*/ "../pages/order-screen/displays-list.page"));
const DisplaysPage = lazy(() => import(/*webpackChunkName: "displays"*/ "../pages/displays.page"));
const StatisticsPage = lazy(() => import(/*webpackChunkName: "statistics"*/ "../pages/statistics.page"));
const ClustersPage = lazy(() => import(/*webpackChunkName: "clusters"*/ "../pages/clusters.page"));
// TV pages
const TvWelcomePage = lazy(() => import(/*webpackChunkName: "TvWelcomePage"*/ "../pages/tv/welcome.page"));

// Admin
const AdminMenuPage = lazy(() => import(/*webpackChunkName: "admin-menu"*/ "../pages/admin/admin-menu.page"));
const AdminDevicesList = lazy(() => import(/*webpackChunkName: "admin-devices-list"*/ "../pages/admin/devices-list.page"));

// Demo
const DemoStatisticsPage = lazy(() => import(/*webpackChunkName: "statistics-demo"*/ "../pages/demo/demo-statistics.page"));
const protectedLoader = (ctx: LoaderFunctionArgs) => {
    const {request} = ctx;
    let isAuthorized = localStorage.getItem('token')
    const appStore: { state?: IAppStore } = JSON.parse(localStorage.getItem('appStore') ?? '{}')
    let accountId = new URLSearchParams(window.location.search).get('accountId')
    const isTvMode = !!appStore?.state?.isTVMode
    if (!isAuthorized && !accountId) {
        const url = new URL(request.url);

        if (isTvMode) {
            return redirect(
                formatUrl(ROUTES.TV_WELCOME),
            );
        }
        return redirect(
            formatUrl(ROUTES.LOGIN, {from: `${new URL(request.url).pathname}${url.search}`}),
        );
    }


    return request;
};

const checkAndRedirectLoader = (ctx: LoaderFunctionArgs) => {
    const {request} = ctx;
    const appStore: { state?: IAppStore } = JSON.parse(localStorage.getItem('appStore') ?? '{}')

    const search = new URLSearchParams(request.url.split('?')[1])

    if (search.get('source') === 'tv') {
        set(appStore, 'state.isTVMode', true)
        localStorage.setItem('appStore', JSON.stringify(appStore))
    }

    const isTvMode = !!appStore?.state?.isTVMode

    let isAuthorized = localStorage.getItem('token')

    if (!isAuthorized) {
        if (isTvMode) {
            return redirect(
                formatUrl(ROUTES.TV_WELCOME),
            );
        }
        return redirect(
            formatUrl(ROUTES.LOGIN, {from: new URL(request.url).pathname}),
        );
    }

    if (isTvMode) {

        return redirect(buildRoute(ROUTES.SCREENS_LIST))
    }

    return redirect(ROUTES.STATIONS)
}
export const appRouter = createBrowserRouter([{
    path: ROUTES.SQUARE_REGISTER,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: SquareAuthForm,
}, {
    path: ROUTES.LOGIN,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: Login,
}, {
    path: ROUTES.REGISTER,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: Register,
}, {
    // path: '/',
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    element: <MainLayout/>,
    loader: protectedLoader,
    children: [
        {
            path: ROUTES.STATIONS,
            index: true,
            Component: Dashboard
        }, {
            path: ROUTES.SETTINGS,
            Component: Settings
        }, {
            path: ROUTES.DISPLAYS_LIST,
            Component: DisplaysPage,
        }, {
            path: ROUTES.STATISTICS,
            Component: StatisticsPage,
        },{
            path: ROUTES.CLUSTERS,
            Component: ClustersPage,
        },
    ]
}, {
    loader: protectedLoader,
    hasErrorBoundary: true,
    errorElement: <GenericError status={500}/>,
    element: <TvLayout/>,
    children: [
        {
            path: ROUTES.SCREENS_LIST,
            index: true,
            Component: ScreensList,
        }, {
            path: ROUTES.ORDERS_SCREEN,
            Component: OrdersScreen,
        }
    ]
}, {
    loader: protectedLoader,
    hasErrorBoundary: true,
    errorElement: <GenericError status={500}/>,
    element: <AdminLayout/>,
    children: [
        {
            path: ADMIN_ROUTES.ADMIN_MENU,
            index: true,
            Component: AdminMenuPage
        }, {
            path: ADMIN_ROUTES.ADMIN_DEVICES_LIST,
            Component: AdminDevicesList,
        }
    ]
}, {
    element: <NoSidebarLayout/>,
    children: [{
        path: '/demo/statistics',
        index: true,
        Component: DemoStatisticsPage
    }]
}, {
    path: '/',
    loader: checkAndRedirectLoader,
}, {
    path: ROUTES.TV_WELCOME,
    Component: TvWelcomePage
}, {
    path: "*",
    element: <GenericError status={404}/>,
}])